
import Vue from "vue";
import { BRow, BCol } from "bootstrap-vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AvCheckbox from "@/components/av-checkbox/AvCheckbox.vue";
import AvDivider from "@/components/av-divider/AvDivider.vue";
import EventsHorizontalCard from "@/views/administrative/settings/events/components/EventsHorizontalCard.vue";
import { IEventoPerfil } from "../interfaces/IEventsProfile";
import { IEventByProfile } from "@core/services/interfaces/administrator/IAdministratorService";

@Component({
  name: "CollapseHorizontalCard",
  components: {
    BRow,
    BCol,
    AppCollapseItem,
    AvCheckbox,
    AvDivider,
    EventsHorizontalCard,
  },
})
export default class CollapseHorizontalCard extends Vue {
  @Prop() item!: IEventoPerfil;
  @Prop({ default: "" }) collapseID!: string;

  checkedPerfis: boolean = false;
  indeterminateCheck: boolean = false;
  itemsAlterados!: IEventoPerfil;

  @Watch("item", { immediate: true, deep: true })
  onItemChange(newValue: IEventoPerfil) {
    this.itemsAlterados = newValue;
    this.verificaChecked(newValue);
  }

  get eventosArray(): IEventByProfile[] {
    return this.itemsAlterados.eventos;
  }

  emitEventoIds(evento: IEventByProfile): void {
    this.$emit("changeEvent", evento);
  }

  verificaChecked(item: IEventoPerfil): void {
    if (item.checkFull) {
      this.indeterminateCheck = false;
      this.checkedPerfis = true;
    }

    if (
      !item.checkFull &&
      item.countEventosAtivos < item.eventos.length &&
      item.countEventosAtivos > 0
    ) {
      this.indeterminateCheck = true;
    }

    if (item.countEventosAtivos === 0) {
      this.checkedPerfis = false;
      this.indeterminateCheck = false;
    }
  }
}
