<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    ></div>
    <div class="email-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Buscar perfil"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper email-user-list scroll-area"
      >
        <ul class="todo-task-list media-list">
          <li
            v-for="profile in profilesLocal"
            :key="profile.id"
            class="todo-item"
            @click="handleProfileClick(profile)"
          >
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <span class="todo-title">{{ profile.nome }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge
                    pill
                    :variant="`light-${resolveTagVariant(profile.status)}`"
                    class="text-capitalize"
                  >
                    {{
                      profile.status ? $ENUMS.TEXT.ATIVO : $ENUMS.TEXT.INATIVO
                    }}
                  </b-badge>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div class="no-results" :class="{ show: !profilesLocal.length }">
          <h5>Nenhum evento encontrado</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Event View/Detail -->
    <profile-view
      :class="{ show: showProfileDetails }"
      :profile-view-data="profileViewData"
      :opended-profile-meta="opendedProfileMeta"
      @close-profile-view="showProfileDetails = false"
      @change-opened-profile="changeOpenedProfile"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <settings-left-sidebar
        :settings-tags="settingsTags"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import ENUMS from "@/enums";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import { avatarText, formatDate } from "@core/utils/filter";
import { useRouter } from "@core/utils/utils";
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { computed, ref, watch } from "vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import ProfileView from "./events/ProfileView.vue";
import SettingsLeftSidebar from "./SettingsLeftSidebar.vue";

// Services
import { useAdministrator } from "@/services";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    SettingsLeftSidebar,
    ProfileView,
  },
  created() {
    this.settingsTags = [
      {
        title: this.$ENUMS.TEXT.ATIVO,
        color: "primary",
        route: {
          name: "administrative-settings-event-tag",
          params: { tag: this.$ENUMS.TEXT.ATIVO },
        },
      },
      {
        title: this.$ENUMS.TEXT.INATIVO,
        color: "danger",
        route: {
          name: "administrative-settings-event-tag",
          params: { tag: this.$ENUMS.TEXT.INATIVO },
        },
      },
    ];

    this.textAtivo = this.$ENUMS.TEXT.ATIVO;
    this.textInativo = this.$ENUMS.TEXT.INATIVO;
  },
  setup() {
    // Data
    const { route, router } = useRouter();
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const profiles = ref([]);
    const profilesLocal = ref([]);
    const perfectScrollbarSettings = { maxScrollbarLength: 150 };
    let settingsTags = [];
    const $ENUMS = ENUMS;

    // Computeds
    const routeQuery = computed(() => route.value.query.q);
    const routeParams = computed(() => route.value.params);

    // Data
    const searchQuery = ref(routeQuery.value);

    // Profile Details
    const showProfileDetails = ref(false);
    const profileViewData = ref({});
    const opendedProfileMeta = computed(() => {
      const openedProfileIndex = profilesLocal.value.findIndex(
        (p) => p.Id === profileViewData.value.Id
      );
      return {
        hasNextProfile: Boolean(profilesLocal.value[openedProfileIndex + 1]),
        hasPreviousProfile: Boolean(
          profilesLocal.value[openedProfileIndex - 1]
        ),
      };
    });
    const changeOpenedProfile = (dir) => {
      const openedProfileIndex = profilesLocal.value.findIndex(
        (e) => e.Id === profileViewData.value.Id
      );
      const newProfileIndex =
        dir === "previous" ? openedProfileIndex - 1 : openedProfileIndex + 1;

      profileViewData.value = profilesLocal.value[newProfileIndex];
    };

    // Watchs
    watch(profiles, () => {
      profilesLocal.value = profiles.value;
    });

    watch(routeParams, () => {
      filterProfiles();
    });

    // Search Query
    watch(routeQuery, (val) => {
      searchQuery.value = val;
    });
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => {
      filterProfiles();
    });

    // Functions
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      delete currentRouteQuery.sort;

      router
        .replace({ name: route.name, query: currentRouteQuery })
        .catch(() => {});
    };

    const resolveTagVariant = (tag) => {
      if (tag) return "success";
      if (!tag) return "danger";
      return "primary";
    };

    const updateRouteQuery = (val) => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      if (val) currentRouteQuery.q = val;
      else delete currentRouteQuery.q;

      router.replace({ name: route.name, query: currentRouteQuery });
    };

    const fetchListProfiles = () => {
      useAdministrator.requestGetProfiles().then((response) => {
        profiles.value = response.data.data;
      });
    };

    fetchListProfiles();

    const filterProfiles = () => {
      const q = searchQuery.value ? searchQuery.value : "";
      const filter = Object.entries(router.currentRoute.params).length
        ? router.currentRoute.params.tag
        : null;

      profilesLocal.value = profiles.value.filter((profile) => {
        if (profile.Nome.includes(q)) {
          if (typeof filter == "string") {
            status = profile.Status ? "Ativo" : "Inativo";
            if (status == filter) return true;
            else return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      });
    };

    const handleProfileClick = (profileData) => {
      showProfileDetails.value = true;
      profileViewData.value = profileData;
    };

    return {
      profilesLocal,
      settingsTags,
      searchQuery,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,

      // Click Handler
      handleProfileClick,

      // Filters
      formatDate,
      avatarText,

      // Profile View
      showProfileDetails,
      profileViewData,
      opendedProfileMeta,
      changeOpenedProfile,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "~@core/scss/base/pages/app-email.scss";
</style>
